
import { defineComponent } from "vue";
import Container from "../../components/Structure/Container.vue";
import {
  Header,
  SingleCheckbox,
  QuestionWrapper,
} from "../../components/Questions";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    Container,
    Header,
    SingleCheckbox,
    QuestionWrapper,
  },
  setup() {
    const questionNumber = 1;
    const router = useRouter();

    const title = "When did you last have unprotected sex?";
    const options = [
      {
        label: "0-3 days ago",
        value: "0-3 days ago",
      },
      {
        label: "4-5 days ago",
        value: "4-5 days ago",
      },
      {
        label: "More than 6 days ago",
        value: "More than 6 days ago",
      },
      {
        label: "I didn't, I just want to be prepared",
        value: "I didn't, I just want to be prepared",
      },
    ];

    const onAnswered = () => {
      router.push(String(questionNumber + 1));
    };

    return {
      options,
      onAnswered,
      questionNumber,
      title,
    };
  },
});
